import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [0,3,4,5];

export const dictionary = {
		"/": [6],
		"/(marketing)/about": [7,[2]],
		"/admin/campaign/list": [~13,[3]],
		"/admin/campaign/[id]": [~12,[3]],
		"/admin/dashboard": [~14,[3]],
		"/admin/investment/edit": [~15,[3]],
		"/admin/investment/list": [~16,[3]],
		"/admin/mail/template/list": [~18,[3]],
		"/admin/mail/template/[id]": [~17,[3]],
		"/admin/match/irrelevant": [~19,[3]],
		"/admin/match/users/buyers": [~20,[3]],
		"/admin/match/users/buyers/ignoring": [~21,[3]],
		"/admin/match/users/sellers": [~22,[3]],
		"/admin/member/employer/create": [~27,[3]],
		"/admin/member/employer/[id]/edit": [~24,[3]],
		"/admin/member/employer/[id]/employee/create": [~26,[3]],
		"/admin/member/employer/[id]/employee/[uid]/transfer": [~25,[3]],
		"/admin/member/list": [~28,[3]],
		"/admin/member/onboarding": [~29,[3]],
		"/admin/member/[id]": [~23,[3]],
		"/admin/profile/fit/notify": [~30,[3]],
		"/admin/profile/fit/problem": [~31,[3]],
		"/admin/settings": [~32,[3]],
		"/admin/settings/test": [~33,[3]],
		"/auth/initiation": [~34,[4]],
		"/auth/initiation/thanks": [~35,[4]],
		"/auth/password": [~36,[4]],
		"/auth/password/sent": [37,[4]],
		"/auth/signin": [~38,[4]],
		"/auth/signout": [39,[4]],
		"/(marketing)/contact": [8,[2]],
		"/(marketing)/faq": [9,[2]],
		"/(marketing)/legal": [10,[2]],
		"/member/dashboard": [~40,[5]],
		"/member/employer/employee/create": [~42,[5]],
		"/member/employer/employee/list": [~43,[5]],
		"/member/employer/employee/[eid]": [~41,[5]],
		"/member/investment/create": [~45,[5]],
		"/member/investment/matches/irrelevant": [~46,[5]],
		"/member/investment/matches/relevant": [~47,[5]],
		"/member/investment/partners": [~48,[5]],
		"/member/investment/partners/decision": [~49,[5]],
		"/member/investment/profile": [~50,[5]],
		"/member/investment/requests": [~51,[5]],
		"/member/investment/[id]": [~44,[5]],
		"/member/settings": [~52,[5]],
		"/member/settings/password": [~53,[5]],
		"/member/unsubscribe": [~54,[5]],
		"/(marketing)/search": [~11,[2]],
		"/sentry-example": [55]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';